import { feeDesc, mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

export default {
  name: "feeStandardRule",
  mixins: [mixins],
  data() {
    return {
      columns: [{
        type: 'expand',
        typeSlot: 'itemExpand'
      }, {
        label: '隔日计费类型',
        width: '120px',
        prop: 'nextDayType'
      }, {
        label: '区域',
        prop: 'areaName'
      }, {
        label: '周末是否计费',
        customRender: props => {
          return props.row.weekend ? '计费' : '不计费';
        }
      }, {
        label: '法定假日是否计费',
        customRender: props => {
          return props.row.holiday ? '计费' : '不计费';
        }
      }, {
        label: '封顶金额',
        customRender: props => {
          if (props.row.maxFee > 0) {
            return props.row.maxFee;
          } else {
            return '不封顶';
          }
        }
      }, {
        label: '超运营停放调度费用',
        prop: 'outFenceFee'
      }, {
        label: '站外停放调度费用',
        prop: 'nonParkFee'
      }, {
        label: '生效日期',
        prop: 'beginDay'
      }, {
        label: '失效日期',
        prop: 'endDay',
        customRender: props => {
          var _props$row$endDay;
          return (_props$row$endDay = props.row.endDay) !== null && _props$row$endDay !== void 0 ? _props$row$endDay : '长期';
        }
      }, {
        label: '费率描述',
        prop: 'feeDesc'
      }],
      topButtons: [{
        name: '导出',
        code: 'feeStandard_export',
        type: 'primary',
        click: this.inputInfo,
        permissions: ['feeStandard_export']
      }],
      searchItems: [{
        type: 'cascader',
        prop: 'areaId',
        label: '区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }]
    };
  },
  methods: {
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.feeStandardRule.exportInfo(search).then(res => {
        this.blobFile(res, '已生效费率.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    showFeeRuleDesc(feeRule) {
      return feeDesc(feeRule);
    },
    loadItems(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.feeStandardRule.items(item.id).then(res => {
        if (res.code === 200) {
          item.items = res.data;
        } else {
          ElMessage.error(res.message);
          item.items = [];
        }
      });
      return '加载中';
    }
  },
  beforeMount() {}
};